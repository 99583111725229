.close-button {
    background-color: transparent;
    border: none;
    box-shadow: none;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 12px;
}

.file-Upload {
    color: #000000;
    text-align: left;
    margin-top: 1px;
    margin-bottom: 10px;
    font-weight: bold;
}

.enter-title {
    margin-bottom: 10px;
}

.drop-zone {
    border: 2px dashed #9295BF;
    background-color: #f9f9f9;
    color: #9295BF;
    border-radius: 10px;
    width: 100%;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
    
}

.drag-over {
    background-color: #e6e6e6;
}

.DragAndDrop-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.DragAndDrop-container p{
    color: #000000;
    font-size: 12px;
}

.DragAndDrop-img {
    width: 70px;
    height: 70px;
    margin-top: 10px;
}
.text-input-container{
    margin-top: 20px;
}
.text-input{
    width: 100%;
    border: none;
    font-size: 20px;
}
.text-input:focus{
    outline:none;
}

.upload-button-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.MIDI-Upload {
    margin-right: 30px;
}


.MIDI-Upload{
    border: none;
    background-color: #9295BF;
    color: white;
    border-radius: 15px;
    width: 150px;
    height: 30px;
    margin: auto;
}

.goto-youtube-Upload-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: #616380;
    background-color: transparent;
    border: none;
    box-shadow: none;
}
.close-button {
    background-color: transparent;
    border: none;
    box-shadow: none;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 12px;
}

.youtube-link-Upload {
    color: #c5c5c5;
    text-align: center;
    margin-top: 1px;
}


.youtube-link {
    margin-bottom: 10px;
}

.youtube-Upload-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}


.youtube-Upload {
    border: none;
    background-color: #9295BF;
    color: white;
    border-radius: 15px;
    width: 150px;
    height: 30px;
    margin-bottom: 10px;
}

.goto-file-upload-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: #616380;
    background-color: transparent;
    border: none;
    box-shadow: none;
}